import { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

const InViewport = (props) => {
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef, {}, { disconnectOnLeave: true });

  useEffect(() => {
    if (inViewport) {
      props.callback();
    }
  }, [inViewport, props])

  return (
    <div className={props.className} ref={myRef}>
      {props.children}
    </div>
  )
} 
 
export default InViewport 