import TwitterLogo from '../../img/v2/twitter.svg'
import DiscordLogo from '../../img/v2/discord.svg'
import Divider from './Divider'

const Contact = () => {
    return (
        <>
            <div id="contacts" className="min-h-[200px] z-10 bg-[#251c17] w-full flex items-center justify-center gap-16 pt-8 pb-8 text-[#bc9d7e]">
                <div className="w-11">
                    <a href="https://twitter.com/TitansofWar_TCG" target="_blank"><img src={TwitterLogo}></img></a>
                </div>
                <div className="w-11">
                    <a href="https://discord.com/invite/ax3ykcKWGf" target="_blank"><img src={DiscordLogo}></img></a>
                </div>
                <div className="w-11 text-[40px]">
                    <a className='hover:underline' href="http://medium.com/@titansofwar" target="_blank">Blog</a>
                </div>

            </div>
            <Divider />
        </>
    );
}

export default Contact;