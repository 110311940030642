import { useEffect, useState } from 'react';
import hamburger from '../../img/hamburger.svg'
import Logo from '../../img/v2/logoclean.png';
import ImmutableLogo from '../../img/logos/immutablelogo.svg';

function Header() {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {

  
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
        setTimeout(() => { setShowMenu(false)}, 0)
      });
    });
  }, [])

  return (
    <div className="sticky top-0 bg-[#251c17] z-20 h-12 opacity-90 sm:h-24 text-[#bc9d7e] flex border-b-[1px] border-black items-center">
      <div className="ml-8 hidden sm:flex mr-14 xxl:mr-28"><img className="h-[70px] min-w-[90px]" src={Logo} /></div>
      <ul className="gap-8 hidden sm:flex justify-center items-center h-full text-[24px] md:text-[36px] lg:text-[48px]">
        <li>
          <a className="ml-2 lg:ml-5 hover:underline" href="#home">Home</a>
        </li>
        <li>
          <a className="hover:underline" href="#team">Team</a>
        </li>
        <li>
        <a className="hover:underline" href="#esports">Esports</a>
        </li>
        <li>
          <a className="hover:underline" href="#contacts">Social</a>
        </li>
        <li className="w-8 lg:w-11 flex h-full items-center">
          <a className="hover:underline" href="https://titans-of-war.gitbook.io/titans-of-war/" target="_blank" rel="noreferrer">Whitepaper</a>
        </li>
      </ul>
      <div className="ml-8 hidden justify-end w-full xl:flex mr-14 md:mr-28">
          <a href="http://www.immutable.com" target="_blank">
            <img className="h-9 min-w-[90px]" src={ImmutableLogo} />
          </a>
      </div>
      <button className="sm:hidden z-20 ml-2 relative" onClick={() => setShowMenu(!showMenu)}><img alt="hamburger" src={hamburger}></img></button>
      <div className="">
        <ul className={`gap-4 sm:hidden items-center ${showMenu ? 'opacity-100' : 'max-h-0 opacity-0' } text-[36px] flex flex-col top-12 left-0 absolute z-10 bg-[#201c1c] w-full transition-all duration-300`}>
          <li>
            <a className="hover:underline" href="#home">Home</a>
          </li>
          <li>
            <a className="hover:underline" href="#team">Team</a>
          </li>
          <li>
            <a className="hover:underline" href="#esports">Esports</a>
          </li>
          <li>
            <a className="hover:underline" href="#contacts">Social</a>
          </li>
          <li>
            <a className="hover:underline" href="https://titans-of-war.gitbook.io/titans-of-war/" target="_blank" rel="noreferrer">Whitepaper</a>
          </li>          
        </ul>
      </div>
    </div> 
  );
}

export default Header;
