import BOOKBURNER from '../../img/v2/cards/bookburner.jpg'
import DRUSINA from '../../img/v2/cards/drusina.jpg'
import GUNPOWDER from '../../img/v2/cards/gunpowder.jpg'
import TEAPARTY from '../../img/v2/cards/teaparty.jpg'



import Divider from './Divider';
const Economy = () => {
    return (
        <>
        
        <div className="min-h-[400px] bg-cover bg-[url('./img/v2/bgrex.jpg')] text-[#f1d5c0] z-10 relative w-full">
            <Divider></Divider>    
            <div className="flex justify-center items-center">
                <div className=" text-[28px] sm:text-[36px] md:text-[48px] lg:text-[64px] pt-8 text-long-shadow">SUSTAINABLE ECONOMY</div>
            </div>
            <div>
                <ul className="flex flex-col justify-center items-center text-[18px] xs:text-[22px] sm:text-[28px] md:text-[36px] text-[#f1d5c0] text-shadow">
                    <li className="text-center">Sound token system made to last.</li>
                    <li className="text-center">In-game tournaments that honors players and discourages bots.</li>
                    <li className="text-center">Benefits real users, rewards skilled players.</li>
                </ul>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-6 xl:gap-8 pt-8 pb-16">
            
                <div className="flex flex-col justify-center items-center">
                    <div className="w-[250px] xl:w-[300px] rounded-3xl border-[#3d230b] border-2">
                        <img className="border-[6px] border-[#58330f] rounded-3xl" src={DRUSINA}></img>
                    </div>
                    <div className="text-[24px] text-long-shadow">Varangian Drusina Boyar</div>
                </div>
                
                <div className="flex flex-col justify-center items-center">
                    <div className="w-[250px] xl:w-[300px] rounded-3xl border-[#3d230b] border-2">
                        <img className="border-[6px] border-[#58330f] rounded-3xl" src={TEAPARTY}></img>
                    </div>
                    <div className="text-[24px] text-long-shadow">Boston Tea Party</div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="w-[250px] xl:w-[300px] rounded-3xl border-[#3d230b] border-2">
                        <img className="border-[6px] border-[#58330f] rounded-3xl" src={BOOKBURNER}></img>
                    </div>
                    <div className="text-[24px] text-long-shadow">Book burner</div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="w-[250px] xl:w-[300px] rounded-3xl border-[#3d230b] border-2">
                        <img className="border-[6px] border-[#58330f] rounded-3xl" src={GUNPOWDER}></img>
                    </div>
                    <div className="text-[24px] text-long-shadow">Gunpowder</div>
                </div>
                
            </div>
            <Divider></Divider>    
        </div>
        </>
    );
}

export default Economy;