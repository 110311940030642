import paradox from "../img/logos/paradox.png";
import starbreeze from "../img/logos/starbreeze.png";
import stillfront from "../img/logos/stillfront.png";
import valve from "../img/logos/valve.jpg";
import wotc from "../img/logos/wotc.webp";
import hearthstone from "../img/logos/hearthstone.png";

const LogoPanel = () => {
  return (
    <div className="bg-black">
      <div className="sm:text-[36px] text-shadow mb-12 flex justify-center pt-10 text-[24px]">
        Previous Experiences
      </div>
      <div className="flex pb-20 justify-around flex-row gap-5 flex-wrap">
        <img className="max-h-[150px]" src={paradox} />
        <img className="max-h-[150px]" src={starbreeze} />
        <img className="max-h-[150px]" src={stillfront} />
        <img className="max-h-[150px]" src={valve} />
        <img className="max-h-[150px]" src={wotc} />
        <img className="max-h-[150px]" src={hearthstone} />
      </div>
    </div>
  );
};

export default LogoPanel;
