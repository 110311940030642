import Hms from '../../img/v2/cards/hms.png'
import Hmsfull from '../../img/v2/cards/hmsfull.png'
import Arrowright from '../../img/v2/arrow.png'
import { useState } from 'react'
import InViewport from '../InViewport'
import gameboard from '../../img/v2/gameboard.jpg'


const Burn = () => {
    const [showHms, setShowHms] = useState(false)
    const [showHmsFull, setShowHmsFull] = useState(false)
    return (
        <div className="z-10 bg-[url('./img/v2/bggunpowder.jpg')] bg-cover w-full min-h-[800px] text-[#f1d5c0]  pt-10 text-center">
            <div className="backdrop-blur-[3px] w-full min-h-[800px] flex flex-col items-center">
                <div className="text-[46px] md:text-[96px] mt-10 mb-10 text-long-shadow">
                    UNIQUE SEASON BURN UPGRADES
                </div>
                <div className="items-center flex-col lg:flex-row flex gap-10 flex-wrap">
                    <img className={`${showHms ? "opacity-100 ease-in-out duration-[2000ms]" : "opacity-0"} max-h-[500px] max-w-[300px]`} src={Hms} />
                    <InViewport callback={() => setShowHms(true)} />
                    <img className="max-h-[100px] max-w-[180px] rotate-z-90 lg:rotate-z-0" src={Arrowright} />
                    <img className={`${showHmsFull ? "opacity-100 ease-in-out duration-[2000ms]" : "opacity-0"} max-h-[500px] max-w-[300px]`} src={Hmsfull} />
                    <InViewport callback={() => setShowHmsFull(true)} />
                </div>

                <div className="text-[18px] sm:text-[28px] md:text-[36px] text-shadow max-w-[900px] ml-2 mr-2 border-8 rounded-xl border-[#c8a786] text-[#c8a786] mt-8 bg-[#261c17]">
                    Burning 4 of the same card turns it into a new enhanced NFT Version. The NFT design changes every season, so don't miss out!
                </div>

                <div id="esports" className="text-[46px] md:text-[96px] mt-10 mb-10 text-long-shadow pt-12 pb-12">
                    ESPORTS COMMUNITY
                </div>
                <img className="border-[6px] border-black" src={gameboard} />
                <div className="text-[18px] sm:text-[28px] md:text-[36px]  text-shadow max-w-[900px] ml-2 mr-2 border-8 rounded-xl border-[#c8a786] text-[#c8a786] mt-12 p-4 bg-[#261c17]">
                    World Championship in season 2 with a massive prize pool. Inspired by "The International" the prize pool is increased by the actions,
                    transactions and purchases made by the community. Enabled by blockchain web3 technology.
                </div>
                <div className="pb-8"></div>
            </div>
        </div>
    );
}

export default Burn;