import { useEffect, useState } from "react";
import cardback from '../img/cardback.png'

function FlipCard({ cardfront, flip, minDelay }) {
  const [doFlip, setDoFlip] = useState(false)
  const [delayOk, setDelayOk] = useState(minDelay ? false : true);

  setTimeout(() => {
    setDelayOk(true)
  }, minDelay)
  useEffect(() => {
    if (flip === true)
    setDoFlip(true);
  },  [flip]);
  return (
    <div className={`z-[1] cursor-pointer w-[250px] h-[390px] preserve-3d transition-transform duration-1000 shrink-0 group ${(doFlip && delayOk) ? 'rotate-y-180' : ''}`} onClick={() => { setDoFlip(!doFlip) }}>
      <div className="backface-hidden absolute -inset-[0.5em] bg-gradient-to-r from-[#d6430f] to-[#f2d028] rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
      <div className="backface-hidden absolute top-0 left-0 w-full h-full">
      <img src={cardback} />
      </div>
      <div className="backface-hidden rotate-y-180 bg-transparent absolute top-0 left-0 w-full h-full">
        <div className="absolute -inset-[0.5em] bg-gradient-to-r from-[#d6430f] to-[#f2d028] rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>      
        <img className="w-[350px] max-w-[350px] relative top-[-43px] left-[-50px]" src={cardfront} />
      </div>      
    </div>
  );
}

export default FlipCard;
