import max from '../img/team/max.jpg'
import joel from '../img/team/joel.jpg'
import patrick from '../img/team/patrick.jpg'
import rikard from '../img/team/rikard.jpg'
import marco from '../img/team/marco.jpg'
import peter from '../img/team/peter.jpg'
import olle from '../img/team/olle.jpg'
import mats from '../img/team/mats.png'
import graham from '../img/team/graham.png'
import sebastian from '../img/team/sebastian.png'
import aidan from '../img/team/aidan.png'
import matheus from '../img/team/matheus.png'
import marcello from '../img/team/marcello.jpg'
import tobias from '../img/team/tobias.png'
import Divider from './v2/Divider'


const TeamPanel = () => {
    return (
        <>
        <Divider />
            <div className="z-10 bg-[url('./img/treelightbg.jpeg')] bg-cover w-full min-h-[800px] flex flex-col items-center pt-8 text-center pt-8 pb-8">
                <div className="text-[18px] sm:text-[28px] md:text-[36px]  text-shadow max-w-[900px] ml-2 mr-2 border-8 rounded-xl border-[#c8a786]  text-[#c8a786] mt-12 p-4 bg-[#261c17]">
                    <div>AAA</div>
                    Concept, game mechanics, artwork designed by pro players and veterans of
                    the industry to be the next competitive card game contender.
                </div>
                <div id="team" className="text-[96px] text-long-shadow mt-8 mb-8 text-[#f1d5c0]">
                    Team
                </div>
                <div className="flex flex-col gap-4 text-[24px]">
                    <div className="flex flex-col items-center md:items-start md:flex-row gap-4">
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={joel} />
                            <div className="text-shadow">Joel Larsson</div>
                            <div className="text-shadow">Head of Game design</div>
                        </div>
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={rikard} />
                            <div className="text-shadow">Rikard Stenlund</div>
                            <div className="text-shadow">CTO</div>
                        </div>
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={marcello} />
                            <div className="text-shadow">Marcelo Santana</div>
                            <div className="text-shadow">Art Director</div>
                        </div> 
                    </div>    
                    <div className="flex flex-col items-center md:items-start md:flex-row gap-4">
                        <div className="w-[200px]">
                            <img className="rounded-3xl " src={max} />
                            <div className="text-shadow">Max Jonsson</div>
                            <div className="text-shadow">CEO</div>
                        </div>
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={mats} />
                            <div className="text-shadow">Mats Törnros</div>
                            <div className="text-shadow">Programmer</div>
                        </div>
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={patrick} />
                            <div className="text-shadow">Patrick Jonsson</div>
                            <div className="text-shadow">Vice President</div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center md:items-start md:flex-row gap-4">
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={sebastian} />
                            <div className="text-shadow">Sebastian Läger</div>
                            <div className="text-shadow">Esports Director</div>
                        </div>    
                        
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={graham} />
                            <div className="text-shadow">Graham Martin</div>
                            <div className="text-shadow">Director</div>
                        </div>
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={olle} />
                            <div className="text-shadow">Olle Råde</div>
                            <div className="text-shadow">Game Design</div>
                        </div>   
                    </div>
                    <div className="flex flex-col items-center md:items-start md:flex-row gap-4">
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={matheus} />
                            <div className="text-shadow">Matheus Nascimento</div>
                            <div className="text-shadow">Unity Developer</div>
                        </div>    
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={aidan} />
                            <div className="text-shadow">Aidan Coughlan</div>
                            <div className="text-shadow">3D Artist</div>
                        </div>   
                        <div className="w-[200px]">
                            <img className="rounded-3xl" src={peter} />
                            <div className="text-shadow">Peter Bergström</div>
                            <div className="text-shadow">Executive Producer</div>
                        </div>   
                    </div>
                    <div className="flex flex-col items-center md:items-start md:flex-row gap-4">
                        <div className="mb-8 w-[200px]">
                            <img className="rounded-3xl" src={tobias} />
                            <div className="text-shadow">Tobias Johansson</div>
                            <div className="text-shadow">CMO</div>
                        </div>    
                    </div>
                </div>
            </div>
            <Divider></Divider>    
        </>
    );
}

export default TeamPanel;