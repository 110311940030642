import './App.css'
import './output.css'

import HeroPanel from './components/v2/HeroPanel';
import Header from './components/v2/Header';
import USPPanel from './components/v2/USPPanel';
import Economy from './components/v2/Economy';
import Burn from './components/v2/Burn';
import Tokenomics from './components/Tokenomics';
import TeamPanel from './components/TeamPanel';
import LogoPanel from './components/LogoPanel';
import Contact from './components/v2/Contact';


function App() {
  return (
    <div className="bg-[#37343c] text-white">
        <Header />
        <HeroPanel />
        <USPPanel />
        <Economy />
        <Burn />
        <TeamPanel />
        <Contact />
        <LogoPanel />
    </div>
  );
}

export default App;
