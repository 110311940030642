import Divider from './Divider';
import { useState } from "react"
import FlipCard from "../FlipCard"
import Leader from '../../img/suntzu.png'
import Unit from '../../img/v2/cards/teutonic.png'
import InViewport from '../InViewport'
import Cardback from '../../img/cardback.png'

import PEOPLE from '../../img/v2/icons/peopleicon.png'
import DEVICE from '../../img/v2/icons/deviceicon.png'
import CASTLE from '../../img/v2/icons/castlestaricon.png'
import HAND from '../../img/v2/icons/handicon.png'
import CARDS from '../../img/v2/icons/cardsicon.png'
import STAR from '../../img/v2/icons/staricon.png'
import BINARY from '../../img/v2/icons/binaryicon.png'
import INF from '../../img/v2/icons/inficon.png'
import TowLogo from '../../img/tow-logo-new.png'





const USPPanel = () => {
    const [flipCard1, setFlipCard1] = useState(false)
    const [flipCard2, setFlipCard2] = useState(false)


    const [card1, setCard1] = useState(false)
    const [card2, setCard2] = useState(false)
    const [card3, setCard3] = useState(false)
    const ready = true;
    return (
        <div className="bg-[url('./img/v2/bgchina.jpg')] bg-cover w-full min-h-[500px]">
            <div>
                <div className='flex flex-col sm:flex-row justify-center text-[30px] h-full sm:gap-8 sm:text-[24px] lg:text-[36px] pt-28 pb-12 text-shadow text-[#bc9d7e]'>
                    <div className="flex flex-col flex-wrap gap-20 text-center h-full">
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={PEOPLE} /></div>
                            <div>Community boosted Esport</div>
                            <div>World Championship with major price pool</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={DEVICE} /></div>
                            <div>Play on your favourite</div>
                            <div>device</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={CASTLE} /></div>
                            <div>Revolutionary</div>
                            <div>tournament solution</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={HAND} /></div>
                            <div>Evolving play to earn</div>
                            <div className="text-transparent text-shadow-none">-</div>
                        </div>
                    </div>
                    <div className="hidden sm:flex flex-col flex-wrap gap-20 text-center justify-center opacity-50 md:h-[900px]">
                        <img className="max-w-[200px] lg:max-w-[300px] xl:max-w-[400px]" src={TowLogo}></img>
                    </div>
                    <div className="flex flex-col flex-wrap gap-20 text-center">
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={CARDS} /></div>
                            <div>Designed by the worlds best magic</div>
                            <div>the gathering players</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={STAR} /></div>
                            <div>AAA</div>
                            <div>Game design and mechanics</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={INF} /></div>
                            <div>Infinite possibilities,</div>
                            <div>endless variation</div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            <div className="w-14 h-14"><img src={BINARY} /></div>
                            <div>Sustainable tokenomics</div>
                            <div className="text-transparent text-shadow-none">-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="flex flex-col lg:flex-row gap-0 lg:gap-16 justify-center items-center flex-wrap">
                    <div className="mt-20">
                        <FlipCard minDelay={500} cardfront={Leader} flip={ready && flipCard1}/>
                        <InViewport callback={() => setFlipCard1(true)} />
                    </div>
                    <div className="flex justify-center flex-col mt-28 gap-10 text-[#411d13] text-center pt-9 pb-9">
                        <div className="w-[300px] h-[240px] bg-[url('./img/textbox.png')] bg-contain bg-no-repeat drop-shadow-[0_35px_35px_rgba(0,0,0,0.6)]">
                            <div className="text-[48px] mt-4">Leaders</div>
                            <div className="text-[24px] pl-2 pr-2">With over 30 Leaders you can build the deck you desire played in a way you like.</div>
                        </div>
                        <div className="w-[300px] h-[240px] bg-[url('./img/textbox.png')] bg-contain bg-no-repeat drop-shadow-[0_35px_35px_rgba(0,0,0,0.6)]">
                            <div className="text-[48px] mt-4">Cards</div>
                            <div className="text-[24px] pl-2 pr-2">Choose from a large variety of units, inventions and monuments to craft your deck.</div>
                        </div>
                    </div>
                    <div className="mt-20 mb-16 lg:mb-0">
                        <FlipCard minDelay={500} cardfront={Unit} flip={ready && flipCard2}/>
                    </div>
                </div>
                <div className="lg:hidden">
                    <InViewport callback={() => setFlipCard2(true)} />
                </div>
                <div className="hidden lg:block">
                    <InViewport callback={() => setTimeout(() => {setFlipCard2(true)}, 500)} />                
                </div>
                
            </div>
            <div className="flex flex-col justify-center items-center text-[36px] sm:text-[56px] lg:text-[85px] text-[#f1d5c0] text-long-shadow pt-20">
                <div className="">THE EVOLUTION OF</div>
                <div>PLAY TO EARN</div>
            </div>

            <div className="w-full flex justify-center items-center -mb-[10px] pt-10">
                <div className="w-40 sm:w-80 h-6 border border-dashed border-[#c8a786] border-b-0"></div>
                <div className="w-40 sm:w-80 h-6"></div>
            </div>
            <div className="w-full flex justify-center items-center text-[#c8a786] text-[22px] xs:text-[26px] sm:text-[36px] md:text-[48px] lg:text-[56px] gap-8">
                <span>Play for free</span>
                <span>Play to own</span>
                <span>Master to earn</span>
            </div>
            <div className="w-full flex justify-center items-center -mt-[10px] pb-10">
                <div className="w-40 sm:w-80 h-6"></div>
                <div className="w-40 sm:w-80 h-6 border border-dashed border-[#c8a786] border-t-0"></div>
            </div>
            <div className="relative pt-60">
                <img className={`transition-transform duration-[2000ms] ${card1 ? '-translate-y-28' : ''} left-1/2 ml-[-25px] sm:ml-[-30px] -translate-x-full w-[150px] sm:w-[200px] -rotate-[9deg] sm:-rotate-[20deg] -bottom-64 absolute`} src={Cardback} ></img>
                <img className={`transition-transform duration-[2000ms] ${card2 ? '-translate-y-28' : ''} left-1/2 -translate-x-1/2 w-[150px] sm:w-[200px] -bottom-56 absolute`} src={Cardback} ></img>
                <img className={`transition-transform duration-[2000ms] ${card3 ? '-translate-y-28' : ''} left-1/2 ml-[25px] sm:ml-[30px] w-[150px] sm:w-[200px] rotate-[9deg] sm:rotate-[20deg] -bottom-64 absolute`} src={Cardback} ></img>
            </div>
            <InViewport callback={() => {
                setCard3(true)
                setTimeout(() => {setCard2(true)}, 300);
                setTimeout(() => {setCard1(true)}, 500);
            }} />
        </div>
    );
}

export default USPPanel;